<template>
  <div class="center">
    <el-container class="center-box">
      <el-header class="header-box">
        <HeaderComponent></HeaderComponent>
      </el-header>
      <el-container class="section">
        <el-aside class="menu">
          <Number :name="name" @updateTitle="updateTitle"></Number>
        </el-aside>
        <el-main class="section-center">
          <!-- <div class="header-title" :class="{'header-test': title === '在线检测'}">
            <div>{{title}}</div>
          </div> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderComponent from '@/components/controlHeader.vue'
import Number from '@/components/number.vue'

export default {
  name: 'NumberCenter',
  components: { HeaderComponent, Number },
  data () {
    return {
      name: '',
      title: ''
    }
  },
  created () {
    this.loadTitle()
    this.loadName()
  },
  methods: {
    loadName () {
      const name = JSON.parse(window.localStorage.getItem('ProductData'))
      this.name = name.ProductName
    },
    loadTitle () {
      this.title = sessionStorage.getItem('title') || '产品概览'
    },
    updateTitle (title) {
      this.title = title
      sessionStorage.setItem('title', title)
    }
  }
}
</script>
<style scoped lang="scss">
.center{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .center-box{
    .header-box{
      padding: 0;
      height: 54px !important;
    }
    .section{
      .menu{
        width: auto !important;
      }
      .section-center{
        margin: 0;
        padding: 0;
        overflow-y: auto;
        height: calc(100vh - 54px);
        .header-title{
          display: flex;
          align-items: center;
          text-align: left;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #ebedf0;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #252a3d;
        }
        .header-test{
          display: none;
        }
      }
    }
  }
}
</style>
