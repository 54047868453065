<template>
  <div class="center">
    <div class="login">
      <div class="login-top">
        <img :src="logImg" alt="">
      </div>
      <div class="login-section">
        <div class="section-left">
          <!-- <img src="../../assets/logo/login_banner.png" alt=""> -->
          <img src="../../image/dengluimg.png" alt="">
        </div>
        <div class="section-right">
          <div class="right-from">
            <h2>
              <span>欢迎登录</span>
              <!-- <router-link :to="{name: 'register'}" class="sign">注册新账号</router-link> -->
            </h2>
            <!-- <div v-if="showCode" class="code">
              <div class="left">
                <i class="el-icon-circle-check"></i>
                <p>1122尾号的验证码短信已发送至您的手机</p>
              </div>
              <i class="el-icon-close"></i>
            </div> -->
            <el-form :model="valiFormData" :rules="rules" ref="valiForm">
              <el-form-item label="" prop="mobile">
                <el-input v-model="valiFormData.mobile" placeholder="请输入您的账号/子账号"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input v-model="valiFormData.password" show-password placeholder="请输入您的密码"></el-input>
              </el-form-item>
              <el-form-item label="" prop="checkcode">
                <el-col :span="16">
                  <el-input v-model="valiFormData.checkcode" placeholder="请输入验证码" resize="both">
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <img :src="CaptchaData._ImgsStr" @click="getCaptcha" style="width: 100px; height: 38px; float: right;" />
                </el-col>
              </el-form-item>
            </el-form>
            <el-row>
              <button class="login-btn" type="primary" @click="handleLogin">登录</button>
            </el-row>
            <el-row style="padding-top: 20px;">
              <el-col :span="12" style="text-align: center;">
                <router-link :to="{name: 'password'}" class="switch-login">修改密码</router-link>
              </el-col>
              <el-col :span="12" style="text-align: center;">
                <router-link :to="{name: 'register'}" class="switch-login">注册新账号</router-link>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="login-bottom">
        <!-- <div class="nav-list">
          <router-link to="" v-for="(item,index) in navList" :key="index">
            {{ item.text }}
          </router-link> -->
        <!-- </div> -->
        <p>{{ CompanyName }} {{ HomePage }} {{ FilingNo }}</p>
      </div>
      <div class="service">
        <el-popover
          trigger="hover"
          placement="left"
        >
          <div class="service-text">
            <p class="service-text-item1">在线客服工作时段</p>
            <p class="service-text-item2">8:00-24:00</p>
          </div>
          <!-- <el-button slot="reference">hover 激活</el-button> -->
          <div class="floating1" slot="reference"></div>
        </el-popover>
        <!-- <el-tooltip class="item" effect="dark" :content="'QQ:'+ mobileList[1].Value" placement="left">
          <div class="floating1"></div>
        </el-tooltip> -->
        <el-popover
          placement="left"
          trigger="hover"
        >
          <div class="service-text">
            <p class="service-text-item1">电话联系：</p>
            <p class="service-text-item2">{{ lx_dianhua }}</p>
          </div>
          <div class="floating1 floating2" slot="reference"></div>
        </el-popover>
        <el-popover
          placement="left"
          trigger="hover"
        >
          <div class="service-text">
            <p class="service-text-item1">QQ联系：</p>
            <p class="service-text-item2">{{ lx_QQ }}</p>
          </div>
          <div class="floating1 floating4" slot="reference"></div>
        </el-popover>
        <el-popover
          placement="left"
          trigger="hover"
        >
          <img :src="lx_wangzhi" alt="" class="service-img">
          <div class="floating1 floating3" slot="reference">
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
import userApi from '@/api/userapi'
import commonapi from '@/api/commonapi'
import controlMain from '../../api/control_main'
// import config from '@/config/index'
import { setToken, setUserInfo } from '@/libs/utils'

export default {
  name: 'loginIndex',
  data () {
    return {
      logImg: '',
      valiFormData: {
        mobile: '',
        password: '',
        checkcode: '',
        checkid: ''
      },
      // 验证码数据
      CaptchaData: {
        _Cid: '',
        _ImgsStr: ''
      },
      // 校验规则
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        checkcode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      navList: [
        {
          text: '官网首页',
          url: 'www.baidu.com'
        },
        {
          text: '联系客服',
          url: 'www.baidu.com'
        },
        {
          text: '关于我们',
          url: 'www.baidu.com'
        },
        {
          text: '站长统计',
          url: 'www.baidu.com'
        }
      ],
      mobileList: [],
      showCode: false,
      isCodeSending: false,
      codeButtonText: '获取验证码',
      timer: null,
      countDown: 60,
      Address: '',
      CompanyName: '',
      FilingNo: '',
      lx_dianhua: '',
      lx_QQ: '',
      lx_wangzhi: '',
      HomePage: ''
    }
  },
  created () {
    this.GetServiceInfo()
    // 第一次进来，需要加载验证码
    this.getCaptcha()
    const type = window.localStorage.getItem('_jjkj_web_token')
    if (type) {
      this.$router.push({
        path: '/control'
      })
    }
  },
  methods: {
    handleLogin () {
      this.$refs.valiForm.validate((valid) => {
        if (valid) {
          const _this = this
          userApi.AppLogin(_this.valiFormData).then((res) => {
            // console.log(res)
            if (res.data.Tag === 1) {
              // 登录成功
              // console.log('登录返回信息：'+res.data.Data);

              // 修改报错setToken
              setToken({
                ApiToken: res.data.Data.ApiToken,
                UserId: res.data.Data.UserId
              })
              setUserInfo(res.data.Data)
              this.$router.push({ path: '/control' })
            } else {
              // 刷新验证码
              this.$message({
                message: res.data.Message + '',
                type: 'error'
              })
              this.getCaptcha()
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    startCountdown () {
      this.isCodeSending = true
      this.codeButtonText = `${this.countDown}秒后重试`
      this.timer = setInterval(() => {
        this.countDown -= 1
        if (this.countDown <= 0) {
          clearInterval(this.timer)
          this.isCodeSending = false
          this.codeButtonText = '获取验证码'
          this.countDown = 60
        } else {
          this.codeButtonText = `${this.countDown}秒后重试`
        }
      }, 1000)
    },
    getCaptcha () {
      commonapi.GetCaptcha()
        .then(res => {
          if (res.data.Tag === 1) {
            const _this = this
            _this.CaptchaData._Cid = res.data.Data.KeyId
            _this.valiFormData.checkid = res.data.Data.KeyId
            _this.CaptchaData._ImgsStr = 'data:image/png;base64,' + res.data.Data.Imgs
          }
        })
    },
    GetServiceInfo () {
      controlMain.GetServiceList().then(res => {
        this.logImg = res.data.Data.Logo
        this.Address = res.data.Data.Address
        this.CompanyName = res.data.Data.CompanyName
        this.FilingNo = res.data.Data.FilingNo
        this.HomePage = res.data.Data.HomePage
        res.data.Data.ServiceList.forEach(item => {
          if (item.ServiceType === 0) { // 电话
            this.lx_dianhua = item.Value
          } else if (item.ServiceType === 1) { // QQ
            this.lx_QQ = item.Value
          } else if (item.ServiceType === 2) { // 微信号
          } else if (item.ServiceType === 3) { // 微信图片
            this.lx_wangzhi = item.Value
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.center {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, #fff, #f4f6f8);

  .login {
    align-items: center;
    background: linear-gradient(180deg, #fff, #f4f6f8);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    min-width: 600px;
    padding: 70px 20px 100px;
    position: relative;
    .login-top{
      position: absolute;
      left: 18px;
      top: 22px;
    }
    .login-section {
      background: #fff;
      box-shadow: 0 18px 30px 0 rgba(192, 195, 204, .4);
      display: flex;
      height: 600px;
      width: 1000px;
      .section-left {
        width: 360px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .section-right {
        align-items: center;
        display: inline-flex;
        flex: 1;
        justify-content: center;
        position: relative;

        .right-from {
          max-width: 420px;
          min-width: 320px;
          width: 58%;

          h2 {
            display: flex;
            color: #252a3d;
            font-size: 28px;
            line-height: 40px;
            margin: 0 0 20px;
            padding: 0;
            position: relative;
            font-weight: 500;
            font-weight: bold;

            .sign {
              text-decoration: none;
              color: #252a3d;
              display: block;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              position: absolute;
              right: 0;
              top: 16px;
            }
          }
          .code{
            display: flex;
            height: 38px;
            border: 1px solid #9ee9cb;
            background-color: #f0fff8;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;
            margin-bottom: 16px;
            .left{
              display: flex;
              align-items: center;
              justify-content: left;
              i{
                color: #23c293;
                margin-right: 6px;
                font-size: 18px;
              }
              font-size: 12px;
              color: #6e737f;
            }
          }

          .login-btn {
            span {
              .btn-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }

            width: 100%;
            background: #3a72ff;
            border-color: #3a72ff;
            border: 0;
            box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
            color: #fff;
            // text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            padding-left: 14px;
            padding-right: 14px;
            box-sizing: border-box;
            font-size: 18px;
            text-align: center;
            text-decoration: none;
            line-height: 2.55555556;
            border-radius: 5px;
            -webkit-tap-highlight-color: transparent;
          }
          .divider {
            margin: 11px 0;
            ::v-deep .el-divider__text{
              span{
                color: rgb(153, 153, 153);
              }
            }
          }

          .other {
            text-align: center;
            margin-top: 42px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            span {
              margin-top: 5px;
              display: block;
              font-size: 12px;
              color: #666a77;
            }
          }
        }
        .switch-login {
          font-size: 14px;
          cursor: pointer;
          color: rgb(0, 153, 255);
          text-decoration: none;
        }
      }
    }

    .login-bottom {
      bottom: 20px;
      position: absolute;
      text-align: center;
      width: 100%;

      .nav-list {
        a {
          font-size: 12px;
          background-color: transparent;
          cursor: pointer;
          outline: none;
          text-decoration: none;
          transition: color .3s;
          color: #666a77;
          margin: 0 20px;

          &:hover {
            color: #3a72ff
          }
        }

        p {
          margin: 14px 0 0;
          font-size: 12px;
          color: #c0c3cc;
        }
      }
    }
    ::v-deep .service {
      display: flex;
      position: fixed;
      top: 35%;
      right: 20px;
      flex-direction: column;
      box-shadow: 0 2px 10px 0 rgba(37, 42, 61, 0.1);
      .floating1{
        width: 50px;
        height: 45px;
        text-align: center;
        line-height: 44px;
        border-radius: 2px;
        border-bottom: 1px solid #ededed;
        background: url('../../assets/kefu_online.png') no-repeat 50%;
        background-size: 24px 24px;
      }
      .floating2{
        background: url('../../assets/kefu_phone.png') no-repeat 50%;
        background-size: 24px 24px;
      }
      .floating3{
        background: url('../../assets/kefu_weixin.png') no-repeat 50%;
        background-size: 24px 24px;
      }
      .floating4{
        background: url('../../assets/kefu_fankui.png') no-repeat 50%;
        background-size: 24px 24px;
      }
    }
  }
}
.service-text {
  .service-text-item1 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .service-text-item2 {
    font-size: 18px;
    font-weight: bold;
    color: #409eff;
  }
}
.service-img {
  height: 200px;
  width: 200px;
}
@media screen and (max-width: 999px){
  .center{
    overflow: auto;
    width: auto;
    margin: 0 auto;
    .login{
      width: 1060px;
      margin-left: 70px;
      margin-right: 70px;
    }
    .login-section{

    }
  }
}
</style>
