import request from '../libs/request.js'

export default {

  // 获取Home左侧导航
  GetHomeLeftMenu () {
    return request.request({
      method: 'post',
      url: '/AppUser/GetHomeLeftMenuList'
    })
  },

  // 获取客服
  GetServiceList () {
    return request.request({
      method: 'get',
      url: '/Common/GetServiceList'
    })
  },

  // 上传号段
  UpLoadHaoDuan (params) {
    return request.request({
      method: 'post',
      url: '/ControlApi/GeneralGenerate',
      data: params
    })
  },

  // 获取接口状态码

  GetApiStatusCode (params) {
    return request.request({
      method: 'post',
      url: '/ControlApi/GetApiCodeList',
      data: params
    })
  },

  // 获取Home页信息
  GetControlHomeInfo () {
    return request.request({
      method: 'get',
      url: '/AppUser/GetControlHomeInfo'
    })
  }
}
