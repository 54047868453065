// import config from '../config/index.js'

const jjkjTokenKey = '_jjkj_web_token'
const jjkjUserInfoKey = '_jjkj_UserInfo_Key'
const jjkjProductInfo = '_jjkj_Product_Info_Key'

// 获取Token
export const getToken = () => {
  return JSON.parse(window.localStorage.getItem(jjkjTokenKey))
  // return uni.getStorageSync(_jjkj_TokenKey)
}

// // 设置Token
export const setToken = (token) => {
  if (token) {
    // 修改成localStorage.setItem
    window.localStorage.setItem(jjkjTokenKey, JSON.stringify(token))
  }
}

// // 设置个人信息
export const setUserInfo = (data) => {
  if (data) {
    // localStorage.setItem(jjkjUserInfoKey, data)
    window.localStorage.setItem(jjkjUserInfoKey, JSON.stringify(data))
  }
}

// // 获取个人信息
export const getUserInfo = () => {
  return JSON.parse(window.localStorage.getItem(jjkjUserInfoKey))
  // return uni.getStorageSync(_jjkj_UserInfoKey)
}

// // 删除token
export const removeToken = () => {
  window.localStorage.removeItem(jjkjTokenKey)
}

// // 设置产品列表
export const setProductListInfo = (data) => {
  if (data) {
    // uni.setStorageSync(_jjkj_ProductInfo, data)
    window.localStorage.setItem(jjkjProductInfo, JSON.stringify(data))
  }
}

// // 获取产品列表
export const getProductListInfo = () => {
  return JSON.parse(window.localStorage.getItem(jjkjProductInfo))
}

// // 获取缓存
// export const getStorage = (key) => {
//   return uni.getStorageSync(key)
// }

// // 设置缓存
// export const setStorage = (key, value) => {
//   if (key && value) {
//     uni.setStorageSync(key, value)
//   }
// }

// // 删除缓存
// export const removeStorage = (key) => {
//   uni.removeStorageSync(key)
// }
