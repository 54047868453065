<template >
  <div class="menu" v-if="handleResize">
    <el-row class="tac">
      <h2 >{{name}}</h2>
      <el-col class="tac-box">
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          text-color="#333"
          active-text-color="#409EFF"
          router
          >
          <template v-for="item in numList">
            <el-menu-item
              v-if="!item.children"
              :key="item.index"
              :index="item.route"
              @click="navigate(item)">
              {{ item.name }}
            </el-menu-item>
            <el-submenu v-else :key="item.index" :index="item.index">
              <template slot="title">{{ item.name }}</template>
              <el-menu-item
                v-for="child in item.children"
                :key="child.index"
                :index="child.route"
                @click="navigate(child)"
                >
                {{ child.name }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    name: String
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      // 全部菜单项
      numList: [
        { name: '产品概览', index: '1', route: '/numberCenter/overview' },
        { name: '在线检测', index: '2', route: '/numberCenter/detection' },
        // {
        //   name: '在线检测',
        //   index: '2',
        //   children: [
        //     { name: '在线检测', index: '2-1', route: '/numberCenter/detection' },
        //     { name: '检测记录', index: '2-2', route: '/numberCenter/record' }
        //   ]
        // },
        {
          name: '接口调试',
          index: '4',
          children: [
            { name: '接口详情', index: '4-1', route: '/numberCenter/Interface' },
            { name: '状态码', index: '4-2', route: '/numberCenter/apicode' },
            { name: '测试', index: '4-3', route: '/numberCenter/test' }
          ]
        },
        {
          name: '数据统计',
          index: '3',
          children: [
            { name: '充值记录', index: '3-1', route: '/numberCenter/chargerecord' },
            { name: '消耗统计', index: '3-2', route: '/numberCenter/statistics' },
            { name: 'API消耗记录', index: '3-3', route: '/numberCenter/apiuserecord' }
          ]
        }

        // { name: '充值', index: '5', route: '/numberCenter/webHome' }
        // { name: '账户配置', index: '5', route: '' }
      ]
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    // 提取localstorage里面储存的ProductData，后期删除通过父组件传递
    const ProductData = JSON.parse(window.localStorage.getItem('ProductData'))
    this.numList[1].route = '/numberCenter/detection/' + ProductData.ProductKey
    // console.log(ProductData)
    // console.log(this.routerUrl)
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
      console.log(this.windowWidth)
      console.log(this.windowWidth > 1000)
      return this.windowWidth > 1000 // 设定宽度阈值，比如 768px
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    navigate (item) {
      this.$emit('updateTitle', item.name)
      if (this.$route.path !== item.route) {
        this.$router.push(item.route).catch(() => {})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tac{
  height: 100vh;
  overflow: hidden;
  width: 180px;
  h2{
    padding: 0 0 0 24px;
    //margin-top: 18px;
    // background-color: #f4f6f8;
    color: #252a3d;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tac-box{
    height: 100vh;
    // background-color: #f4f6f8 !important;
    ::v-deep .el-menu{
      border: none;
      // background-color: #f4f6f8 !important;
      .el-menu--inline{
        .el-menu-item{
          height: 42px;
          line-height: 42px;
          padding-left: 30px !important;
        }
      }
      .el-menu-item{
        height: 42px;
        line-height: 42px;
        text-align: left;
        padding-left: 24px !important;
        // background-color: #f4f6f8 !important;
        padding-right: 0 !important;
        max-width: 180px;
        min-width: 180px;
      }
      .el-menu-item.is-active{
        width: 100%;
        background-color:rgb(91 107 232 / 24%);  //rgba(91, 107, 232, 0.15);            //#fff !important;
        color:#3a72ff !important;        // #3a72ff !important;
      }
      .el-submenu{
        text-align: left;
        padding-left: 4px !important;
        padding-right: 0 !important;
        // background-color: #f4f6f8 !important;
        .el-submenu__title{
          height: 42px;
          line-height: 42px;
          // background-color: #f4f6f8 !important;
        }
      }
    }
  }
}
</style>
