<template>
  <div class="menu">
    <el-row class="tac">
      <h2>{{name}}</h2>
      <el-col class="tac-box">
        <el-menu
          :default-active='morenxunzhong'
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#f0f2f5"
          text-color="#333"
          active-text-color="#409EFF">
          <el-menu-item v-for="item in menuItems" :key="item.index" :index="item.index" @click="navigate(item)">
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
          <el-submenu v-for="item in submenuItems" :key="item.index" :index="item.index" >
            <template slot="title">{{ item.name }}</template>
            <el-menu-item v-for="child in item.children" :key="child.index" :index="child.index" @click="navigate(child)">
              {{ child.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  props: {
    name: String
  },
  data () {
    return {
      morenxunzhong: '1',
      // 全部菜单项
      menuList: [
        { name: '基本资料', index: '1', route: '/account/profile' },
        { name: '企业认证', index: '2', route: '/account/firm' },
        { name: '实名认证', index: '3', route: '/account/realName' },
        // { name: '安全设置', index: '4', route: '/account/security' },
        { name: '登录日志', index: '5', route: '/account/logs' }
        // {
        //   name: '辅助账号管理',
        //   index: '6',
        //   children: [
        //     { name: '子菜单1', index: '6-1', route: '' },
        //     { name: '子菜单2', index: '6-2', route: '' }
        //   ]
        // },
        // {
        //   name: '子账号管理',
        //   index: '7',
        //   children: [
        //     { name: '子菜单1', index: '7-1', route: '' },
        //     { name: '子菜单2', index: '7-2', route: '' }
        //   ]
        // }
      ],
      currentMenuList: [] // 当前显示的菜单项
    }
  },
  computed: {
    menuItems () {
      return this.menuList.filter(item => !item.children)
    },
    submenuItems () {
      return this.menuList.filter(item => item.children)
    }
  },
  watch: {
    $route (to, from) {
      // 当路由变化时触发
      // 右上角点击进入页面导致选择不准 这里做个判断
      this.tongbuzsj()
    }
  },
  mounted () {
    // 刷新页面导致选择不准 这里做个判断
    this.tongbuzsj()
    this.currentMenuList = this.menuList // 初始加载全部菜单
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    tongbuzsj () { // 刷新页面和新进页面 导致左边选择不准确 这里做个判断
      if (this.$route.path === '/account/logs') {
        this.$emit('updateTitle', '登录日志')
        this.morenxunzhong = '5'
      } else if (this.$route.path === '/account/realName') {
        this.$emit('updateTitle', '实名认证')
        this.morenxunzhong = '3'
      } else if (this.$route.path === '/account/firm') {
        this.$emit('updateTitle', '企业认证')
        this.morenxunzhong = '2'
      } else if (this.$route.path === '/account/profile') {
        this.$emit('updateTitle', '基本资料')
        this.morenxunzhong = '1'
      }
    },
    navigate (item) {
      console.log(item.name + '----' + item.route)
      if (this.$route.path !== item.route) {
        this.$emit('updateTitle', item.name)
        this.$router.push(item.route).catch(() => {})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tac{
  height: 100vh;
  overflow: hidden;
  width: 180px;
  h2{
    padding: 0 0 0 24px;
    margin: 0;
    background-color: #f4f6f8;
    color: #252a3d;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tac-box{
    height: 100vh;
    background-color: #f4f6f8 !important;
    ::v-deep .el-menu{
      border: none;
      background-color: #f4f6f8 !important;
        .el-menu--inline{
          .el-menu-item{
            height: 42px;
            line-height: 42px;
            padding-left: 30px !important;
          }
        }
        .el-menu-item{
          height: 42px;
          line-height: 42px;
          text-align: left;
          padding-left: 24px !important;
          background-color: #f4f6f8 !important;
          padding-right: 0 !important;
          max-width: 180px !important;
          min-width: 180px !important;
        }
      .is-active{
        width: 100%;
        background-color: #fff !important;
        color: #3a72ff !important;
      }
      .el-submenu{
          text-align: left;
          padding-left: 4px !important;
          padding-right: 0 !important;
          background-color: #f4f6f8 !important;
          .el-submenu__title{
            height: 42px;
            line-height: 42px;
            background-color: #f4f6f8 !important;
          }
        }
    }
  }
}
</style>
