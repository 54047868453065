<template>
  <div class="center">
    <el-container class="center-box">
      <el-header class="header-box">
        <HeaderComponent></HeaderComponent>
      </el-header>
     <el-container class="section">
       <el-aside class="menu">
         <Menu :name="name" @updateTitle="updateTitle"></Menu>
       </el-aside>
       <el-main class="section-center">
         <div class="header-title" :class="{'box-title': title === '企业认证' || title === '安全设置' || title === '实名认证'}">{{title}}</div>
         <router-view></router-view>
       </el-main>
     </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderComponent from '@/components/controlHeader.vue'
import Menu from '@/components/menu.vue'

export default {
  name: 'AccountCenter',
  components: { HeaderComponent, Menu },
  data () {
    return {
      name: '',
      title: ''
    }
  },
  created () {
    this.loadName()
    this.loadTitle()
  },
  methods: {
    loadName () {
      this.name = sessionStorage.getItem('name') || ''
    },
    loadTitle () {
      this.title = sessionStorage.getItem('title') || '基本资料'
    },
    updateTitle (title) {
      this.title = title
      sessionStorage.setItem('title', title)
    }
  }
}
</script>
<style scoped lang="scss">
  .center{
    height: 100vh;
    overflow: hidden;
    width: auto;
    .center-box{
      .header-box{
        padding: 0;
        height: 54px !important;
      }
      .section{
        .menu{
          width: auto !important;
        }
        .section-center{
          margin: 0;
          padding: 0;
          overflow-y: auto;
          height: calc(100vh - 54px);
          .header-title{
            text-align: left;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #ebedf0;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #252a3d;
            width: calc(100% - 40px);
          }
          .box-title{
            width: 1700px;
          }
        }
      }
    }
  }
</style>
