<template>
  <div>
    <el-row class="header">
      <el-col class="left" :span="12">
        <div class="menu-box"  @click="showDrawer = !showDrawer">
          <img v-if="showDrawer === false" src="../assets/menu1.jpg" alt="" class="menu">
          <i v-else class="el-icon-close"></i>
        </div>
        <!-- <img src="../assets/header-icon.png" alt="" class="logo"> -->
        <router-link :to="{name: 'control'}" class="home">
          <i class="el-icon-s-home"></i>
          <span>工作台</span>
        </router-link>
      </el-col>
      <el-col class="right" :span="12">
        <!-- <ul class="list-menu">
          <li>财务</li>
          <li>文档</li>
          <li>客服</li>
          <li>下载</li>
          <li>
            通知
            <span>3</span>
          </li>
        </ul> -->
        <div class="right-header-box">
          <div class="right-header" >
            <img src="../assets/header_default.png" alt="">
            <i class="el-icon-caret-bottom"></i>
            <div class="user-box">
              <div class="box-top">
                <img src="../assets/header_default.png" alt="">
                <div class="right-info">
                  <p>{{ NickName }}</p>
                  <p>{{ Mobile }}</p>
                </div>
              </div>
              <ul class="list-info">
                <li @click="userInfo(1)">
                  <i class="el-icon-document"></i>
                  <span>基本资料</span>
                </li>
                <li @click="userInfo(2)">
                  <i class="el-icon-school"></i>
                  <span>企业认证</span>
                </li>
                <li @click="userInfo(3)">
                  <i class="el-icon-user"></i>
                  <span>个人认证</span>
                </li>
              </ul>
              <div class="footer-info" @click="LogOut">
                <img src="../assets/tuichu.png" alt="">
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="drawer" :class="{'drawer-open': showDrawer}">
      <div class="drawer-content">
        <div class="left-box">
          <ul>
            <div class="li-left-box">
              <div class="li-left">
                <img src="../assets/chanpin1.png" alt="product">
              </div>
              <p class="text">产品与服务</p>
              <i class="el-icon-arrow-right"></i>
              <!-- <i class="el-icon-close" @click="removeFromLeftBox(index)"></i> -->
            </div>
            <li v-for="(item, index) in (UsedProductList.length === 0 ? RecommedProductList : UsedProductList)" :key="index" @click="goTourl(item)">
              <div class="li-left">
                <img :src="getProductImage(item.ProductKey)" alt="product">
              </div>
              <p class="text">{{item.ProductName}}</p>
              <!-- <i class="el-icon-arrow-right" v-if="index === 0"></i> -->
              <!-- <i class="el-icon-close" @click="removeFromLeftBox(index)"></i> -->
            </li>

          </ul>
        </div>
        <div class="center-box">
          <i class="el-icon-close" @click="toggleDrawer"></i>
          <!-- <el-input
            class="input-box"
            placeholder="请输入产品关键词"
            v-model="input4">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input> -->
          <div class="bottom">
            <div class="bottom-left">
              <ul v-for="(item, index) in ProductList" :key="index">
                <div class="top-one">
                  <p>{{item.ParFullName}}</p>
                  <!-- <div>管理中心</div> -->
                </div>
                <li v-for="(items, index) in item.pItems" :key="index" >
                  <div class="item">
                    <span @click="goTourl(items)">{{items.ProductName}}</span>
                    <!-- <el-link :href="items.ProductUrl" :underline="false">{{items.ProductName}}</el-link> -->
                    <span :class="{'selectIcon': items.IsFixed === 1}" @click="addToLeftBox(items.pId)"></span>
                  </div>
                </li>
              </ul>
              <!-- <ul>
                <li>
                  <p>消息服务</p>
                  <div class="item">
                    <span>短信验证码</span>
                    <span></span>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span>短信验证码</span>
                    <span></span>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span>短信验证码</span>
                    <span></span>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span>短信验证码</span>
                    <span></span>
                  </div>
                </li>
              </ul> -->

            </div>
            <!-- <div class="bottom-right">
              <p>产品推荐</p>
              <ul>
                <li>
                  会员营销短信
                </li>
                <li>
                  会员营销短信
                </li>
                <li>
                  会员营销短信
                </li>
                <li>
                  会员营销短信
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="showDrawer" @click="toggleDrawer"></div>
  </div>
</template>
<script>
import userAxios from '../api/userapi'
import { getUserInfo, removeToken } from '../libs/utils'

export default {
  name: 'headerHome',
  data () {
    return {
      NickName: '',
      Mobile: '',
      showDrawer: false,
      input4: '',
      iconSelect: false,
      ProductList: [],
      RecommedProductList: [],
      UsedProductList: [],
      leftBoxItems: [
        {
          text: '产品与服务',
          icon: true,
          img: require('../assets/chanpin.png')
        },
        {
          text: '产品与服务',
          icon: true,
          img: require('../assets/chanpin.png')
        }
      ],
      menData1: [
        { title: '消息服务', text: '短信验证码 1', iconSelect: false },
        { title: '', text: '短信验证码 2', iconSelect: false },
        { title: '', text: '短信验证码 3', iconSelect: false },
        { title: '', text: '短信验证码 4', iconSelect: false }
      ]
    }
  },
  created () {
    const _Userinfo = getUserInfo()
    // console.log(_Userinfo)
    if (_Userinfo) {
      this.NickName = _Userinfo.NickName
      this.Mobile = _Userinfo.Mobile
    }
    this.GetHomeLeftMenuList()
  },
  methods: {
    GetHomeLeftMenuList () {
      userAxios.GetHomeLeftMenuList().then(res => {
        const resData = res.data.Data
        if (resData) {
          this.ProductList = resData.ProductList
          this.RecommedProductList = resData.RecommedProductList
          this.UsedProductList = resData.UsedProductList
        }
      })
    },
    getProductImage (productKey) {
      try {
        // 尝试加载动态图片
        return require(`@/assets/item/${productKey}.png`)
      } catch (e) {
        // 如果图片不存在，则加载默认图片
        return require('@/assets/chanpin.png')
      }
    },
    LogOut () {
      userAxios.LogOff().then(res => {
        // console.log(res);
        if (res.data.Tag === 1) {
          removeToken()
          this.$router.push({ path: '/' })
        }
      })
    },

    addToLeftBox (id) {
      userAxios.ProductFixed(id).then(res => {
        this.GetHomeLeftMenuList()
      })
    },
    goTourl (item) {
      console.log(item)
      if (item.ProductKey) {
        window.localStorage.setItem('ProductData', JSON.stringify(item))
      }
      if (item.IsActive === 0) {
        this.$confirm('此产品还未激活，是否进行激活？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ActiveProduct(item.pId, item.ProductKey)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消激活'
          })
        })
      }
      if (item.IsActive === 1) {
        this.$router.push({ name: item.ProductKey })
        setTimeout(() => {
          this.$router.go(0)
        }, 500)
      }
    },
    // 激活
    ActiveProduct (Value, ProductKey) {
      const params = {
        ProductId: Value
      }
      userAxios.ActiveProduct(params).then(res => {
        if (res.data.Tag === 1) {
          // 激活成功
          console.log(ProductKey)
          this.$router.push({ name: ProductKey })
          setTimeout(() => {
            this.$router.go(0)
          }, 500)
          this.$message({
            type: 'success',
            message: '已成功激活'
          })
          this.ActiveProductVisible = false
        } else {
          // 激活失败
          this.$message({
            type: 'error',
            message: '激活失败!'
          })
        }
      })
    },
    userInfo (biaoji) { // 1基本资料 2企业认证 3个人认证
      console.log(biaoji)
      // sessionStorage.setItem('name', '账户中心')
      // sessionStorage.setItem('title', '基本资料')
      // console.log(this.$route.name)
      if (biaoji === 1) {
        if (this.$route.name === 'profile') {
          return
        }
        this.$router.replace({ path: '/account/profile' })
      } else if (biaoji === 2) {
        if (this.$route.name === 'firm') {
          return
        }
        this.$router.replace({ path: '/account/firm' })
      } else if (biaoji === 3) {
        if (this.$route.name === 'realName') {
          return
        }
        this.$router.replace({ path: '/account/realName' })
      }
    },
    toggleDrawer () {
      this.showDrawer = !this.showDrawer
    },
    // addToLeftBox (index) {
    //   const item = this.menData1[index]
    //   const existingIndex = this.leftBoxItems.findIndex(leftItem => leftItem.text === item.text)

    //   if (existingIndex !== -1) {
    //     // 项已存在，移除项并取消图标选择
    //     this.leftBoxItems.splice(existingIndex, 1)
    //     this.$set(this.menData1[index], 'iconSelect', false)
    //   } else {
    //     // 项不存在，添加项并选择图标
    //     this.leftBoxItems.push({
    //       text: item.text,
    //       img: require('../assets/chanpin.png'),
    //       fromMenData: index // 标记项来源于 menData1，并记录其索引
    //     })
    //     this.$set(this.menData1[index], 'iconSelect', true)
    //   }
    // },
    removeFromLeftBox (index) {
      const item = this.leftBoxItems[index]

      if (item.fromMenData !== undefined) {
        // 更新 menData1 中的 iconSelect 状态
        this.$set(this.menData1[item.fromMenData], 'iconSelect', false)
      }

      // 移除 leftBoxItems 中的项
      this.leftBoxItems.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(195, 199, 208, .29);
  transition: all .5s;
  background-color: #fff;
  z-index: 13;
  .left {
    display: flex;
    align-items: center;

    .menu-box {
      background-color: #3a72ff;
      width: 180px;
      height: 54px;
      position: relative;

      .menu {
        width: 56px;
        height: 80%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      i{
        font-size: 24px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .logo {
      display: block;
      margin: 0 20px 0 18px;
      width: 104px;
      height: 26px;
    }

    .home {
      margin-left: 20px;
      &:hover{
        border: 1px solid #6395ff;
        i{
          color: #6395ff;
        }
        span{
          color: #6395ff;
        }
      }
      text-decoration: none;
      width: auto;
      height: 28px;
      border-radius: 2px;
      border: 1px solid #dfe1e5;
      box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #666a77;
      }

      span {
        display: block;
        margin-left: 4px;
        font-size: 12px;
        color: #666a77;
      }
    }
  }
  .right{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: right;
    ul,li{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .list-menu{
      display: flex;
      li{
        &:hover{
          background-color: #fafcfb;
          color: #3a72ff;
        }
        line-height: 54px;
        cursor: pointer;
        min-width: 46px;
        text-align: center;
        display: block;
        font-size: 12px;
        color: #252a3d;
        &:last-child{
          position: relative;
          span{
            top: 10px;
            right: 0px;
            position: absolute;
            background-color: #f56c6c;
            border-radius: 50%;
            color: #fff;
            display: inline-block;
            font-size: 12px;
            height: 14px;
            line-height: 14px;
            padding: 0 4px;
            text-align: center;
            white-space: nowrap;
            border: 1px solid #fff;
          }
        }
      }
    }
    .right-header-box{
      height: 54px;
      display: flex;
      align-items: center;
      &:hover{
        .right-header{
          .user-box{
            display: block;
          }
        }
      }
    }
    .right-header{
      min-width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: pointer;
      position: relative;
      img{
        width: 30px;
        height: 30px;
      }
      i{
        display: block;
        margin-left: 6px;
        color: #c0c3cc;
        font-size: 14px;
      }
      .user-box{
        display: none;
        position: absolute;
        top: 40px;
        right: 10px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
        width: 240px;
        text-align: left;
        .box-top{
          display: flex;
          border-bottom: 1px solid #dfe1e5;
          padding: 16px 45px 16px 20px;
          img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;
          }
          .right-info{
            p{
              &:first-child{
                color: #252a3d;
                font-weight: 400;
                line-height: 14px;
                margin: 0 0 4px;
                padding: 0;
                text-align: left;
              }
              &:last-child{
                color: #6f7988;
                line-height: 14px;
                margin: 0;
                padding: 0;
                text-align: left;
              }
            }
          }
        }
        .list-info{
          padding: 8px 0;
          margin-bottom: 1em;
          li{
            display: flex;
            align-items: center;
            width: calc(240px - 20px);
            height: 30px;
            color: #252a3d;
            cursor: pointer;
            font-size: 12px;
            line-height: 32px;
            padding-left: 20px;
            text-align: left;
            &:hover{
              background-color: rgba(73, 125, 255, .1);
            }
            i{
              font-size: 16px;
            }
            span{
              color: #252a3d;
              display: inline-block;
              margin-left: 6px;
            }
          }
        }
        .footer-info{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          border-top: 1px dashed #dfe1e5;
          width: 100%;
          img{
            width: 14px;
            height: 14px;
          }
          span{
            color: #3a72ff;
            cursor: pointer;
            font-size: 12px;
            height: 38px;
            line-height: 38px;
            display: block;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
.drawer {
  position: fixed;
  top: 54px;
  left: -1200px; /* 初始位置在屏幕外 */
  width: 1028px;
  height: calc(100% - 54px);
  background-color: #fff;
  transition: left 0.3s ease;
  z-index: 1000;
  overflow: hidden;
  box-shadow: 0 1px rgba(195, 199, 208, .29) inset
}
.drawer-open {
  left: 0; /* 显示时的位置 */
}

.drawer-content {
  display: flex;
  justify-content: left;
  align-items: center;
  .left-box{
    height: 100vh;
    width: 207px;
    border-right: 1px solid #e0e5ea;
    ul{
      .li-left-box {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        height: 48px;
        cursor: pointer;
        &:hover{
          background-color: #edf2ff;
          .text{
            color:#3a72ff
          }
          .el-icon-close{
            display: block;
            color: #839ff8;
          }
        }
        .li-left{
            width: calc(50px - 16px);
            height: 22px;
            margin-left: 6px;
            img{
              width: 25px;
              height: 25px;
              display: block;
              text-align: center;
              margin: -2px 0px 0 6px;
            }
          }
          .text{
            width: 117px;
            color: #666a77;
            display: block;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            line-height: 20px;
          }
          .el-icon-close{
            display: none;
            font-size: 12px;
          }
      }
      li{
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        height: 48px;
        cursor: pointer;
        &:first-child{
          background-color: #f4f6f8;
          .text{
            color: #252a3d;
          }
        }
        &:hover{
          background-color: #edf2ff;
          .text{
            color:#3a72ff
          }
          .el-icon-close{
            display: block;
            color: #839ff8;
          }
        }
        .li-left{
          width: calc(50px - 16px);
          height: 22px;
          margin-left: 6px;
          img{
            width: 16px;
            height: 16px;
            display: block;
            text-align: center;
            margin: 4px auto 0;
          }
        }
        .text{
          //width: 117px;
          color: #666a77;
          display: block;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          line-height: 20px;
        }
        .el-icon-close{
          display: none;
          font-size: 12px;
        }
      }
    }
  }
  .center-box{
    width: calc(100% - 207px);
    height: 100vh;
    position: relative;
    .el-icon-close{
      z-index: 99;
      font-size: 20px;
      color: #666a77;
      text-align: right;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
    ::v-deep .input-box{
      width: calc(100% - 68px);
      margin: 26px 34px 0;
      input{
        border-radius: 0;
        padding-left: 30px;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid #dfe1e5;
        &:focus{
          border-bottom: 1px solid #dfe1e5;
        }
        &::placeholder{
          color: #666a77;
        }
      }
      .el-input__prefix{
        left: 0;
        color:#666a77
      }
    }
    .bottom{
      display: flex;
      flex: 1;
      padding: 36px 34px 20px 24px;
      height: calc(100vh - 96px);
      width: calc(100% - 58px);
      .bottom-left{
        flex: 1;
        // border-right: 1px solid #dfe1e5;
        column-count: 3;
        padding-right: 20px;
        ul{
          break-inside: avoid;
          margin-bottom: 20px;
          min-height: 60px;
          .top-one{
            position: relative;
            div{
              position: absolute;
              background: #3a72ff;
              border-radius: 2px;
              color: #fff;
              font-size: 12px;
              height: calc(21px - 2px);
              left: 40px;
              margin: 7px 0 0 42px;
              padding: 1px 2px;
              top: -7px;
              line-height: 21px;
              text-align: center;
              &:before{
                border-bottom: 4px solid transparent;
                border-right: 6px solid #3a72ff;
                border-top: 4px solid transparent;
                content: "";
                display: inline-block;
                height: 0;
                left: -5px;
                position: absolute;
                top: 6px;
                width: 0;
              }
            }
          }
          p{
            text-align: left;
            padding: 0 10px;
            color: #252a3d;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin: 0 0 12px;
          }
          .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            color: #666a77;
            cursor: pointer;
            line-height: 32px;
            padding: 0 10px;
            &:hover{
              background-color: #f5f6f7;
              span{
                &:last-child{
                  background-image: url("../assets/ding2.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }
            span{
              &:first-child{
                color: #666a77;
                cursor: pointer;
                line-height: 32px;
                font-size: 14px;
              }
              &:last-child{
                width: 14px;
                height: 14px;
                background-image: url("../assets/ding1.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
            .selectIcon{
              background-image: url("../assets/ding3.png") !important;
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
          li{
          }
        }
      }
      .bottom-right{
        display: flex;
        flex-direction: column;
        width: 180px;
        text-align: left;
        p{
          height: 28px;
          margin-bottom: 12px;
          padding-left: 28px;
          color: #252a3d;
          font-weight: 500;
        }
        ul{
          li{
            cursor: pointer;
            line-height: 32px;
            padding-left: 28px;
            color: #666a77;
            font-size: 12px;
            &:hover{
              color: #ffac2e;
              border-left: 2px solid #ffac2e;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
}
.close-btn {
  background-color: #409EFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
</style>
