import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/index.vue'
import AccountCenter from '@/views/account/AccountCenter.vue'
import NumberCenter from '@/views/numberCenter/NumberCenter.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue')
  },
  // {
  //   path: '/password',
  //   name: 'password',
  //   component: () => import('@/views/login/password.vue')
  // },
  {
    path: '/newPassword',
    name: 'password',
    component: () => import('@/views/login/newPassword.vue')
  },
  {
    path: '/control',
    name: 'control',
    component: () => import('@/views/control/control.vue')
  },
  // {
  //   path: '/webHome',
  //   name: 'webHome',
  //   component: () => import('@/views/webHome/index.vue')
  // },
  {
    path: '/account',
    component: AccountCenter,
    children: [
      {
        path: 'firm',
        name: 'firm',
        component: () => import('@/views/account/firm.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/account/profile.vue')
      },
      {
        path: 'realName',
        name: 'realName',
        component: () => import('@/views/account/realName.vue')
      },
      {
        path: 'security',
        name: 'security',
        component: () => import('@/views/account/security.vue')
      },
      {
        path: 'logs',
        name: 'logs',
        component: () => import('@/views/account/logs.vue')
      }
    ]
  },
  {
    path: '/numberCenter',
    component: NumberCenter,
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('@/views/numberCenter/overview.vue')
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: () => import('@/views/numberCenter/statistics.vue')
      },
      {
        path: 'Interface',
        name: 'Interface',
        component: () => import('@/views/numberCenter/Interface.vue')
      },
      {
        path: 'test',
        name: 'test',
        component: () => import('@/views/numberCenter/test.vue')
      },
      {
        path: 'detection',
        name: 'detection',
        component: () => import('@/views/numberCenter/detection.vue')
      },
      {
        path: 'detection/yw_khzxjc_api',
        name: 'yw_khzxjc_api',
        alias: '/pages/control/product/yw_khzxjc_api/home',
        component: () => import('@/views/numberCenter/detection.vue')
      },
      {
        path: 'detection/yw_khhigh_api',
        name: 'yw_khhigh_api',
        alias: '/pages/control/product/yw_khhigh_api/home',
        component: () => import('@/views/yw_khhigh_api/detection.vue')
      },
      {
        path: 'detection/yw_khplus_api',
        name: 'yw_khplus_api',
        alias: '/pages/control/product/yw_khplus_api/home',
        component: () => import('@/views/yw_khplus_api/detection.vue')
      },
      {
        path: 'detection/yw_khgood_api',
        name: 'yw_khgood_api',
        alias: '/pages/control/product/yw_khgood_api/home',
        component: () => import('@/views/KHCheckGood/detection.vue')
      },
      {
        path: 'detection/yw_khmnp_api',
        name: 'yw_khmnp_api',
        alias: '/pages/control/product/yw_khmnp_api/home',
        component: () => import('@/views/KHCheckMNP/detection.vue')
      },
      {
        path: 'detection/yw_khnewtime_api',
        name: 'yw_khnewtime_api',
        alias: '/pages/control/product/yw_khnewtime_api/home',
        component: () => import('@/views/yw_khnewtime_api/detection.vue')
      },
      {
        path: 'detection/yw_khtwoeles_api',
        name: 'yw_khtwoeles_api',
        alias: '/pages/control/product/yw_khtwoeles_api/home',
        component: () => import('@/views/yw_khtwoeles_api/detection.vue')
      },
      {
        path: 'detection/yw_khbrand_api',
        name: 'yw_khbrand_api',
        alias: '/pages/control/product/yw_khbrand_api/home',
        component: () => import('@/views/yw_khbrand_api/detection.vue')
      },
      {
        path: 'detection/yw_khbrand_ios_api',
        name: 'yw_khbrand_ios_api',
        alias: '/pages/control/product/yw_khbrand_ios_api/home',
        component: () => import('@/views/yw_khbrand_ios_api/detection.vue')
      },
      {
        path: 'detection/yw_khriskno_api',
        name: 'yw_khriskno_api',
        alias: '/pages/control/product/yw_khriskno_api/home',
        component: () => import('@/views/yw_khriskno_api/detection.vue')
      },
      {
        path: 'detection/yw_khisparea_api',
        name: 'yw_khisparea_api',
        alias: '/pages/control/product/yw_khisparea_api/home',
        component: () => import('@/views/yw_khisparea_api/detection.vue')
      },
      {
        path: 'detection/yw_khnosec_hd_api',
        name: 'yw_khnosec_hd_api',
        alias: '/pages/control/product/yw_khnosec_hd_api/home',
        component: () => import('@/views/yw_khnosec_hd_api/detection.vue')
      },
      {
        path: 'detection/yw_khnosec_tq_api',
        name: 'yw_khnosec_tq_api',
        alias: '/pages/control/product/yw_khnosec_tq_api/home',
        component: () => import('@/views/yw_khnosec_tq_api/detection.vue')
      },
      {
        path: 'detection/yw_khthreefour_api',
        name: 'yw_khthreefour_api',
        alias: '/pages/control/product/yw_khthreefour_api/home',
        component: () => import('@/views/yw_khthreefour_api/detection.vue')
      },
      {
        path: 'detection/yw_khonlinedate_api',
        name: 'yw_khonlinedate_api',
        alias: '/pages/control/product/yw_khonlinedate_api/home',
        component: () => import('@/views/yw_khonlinedate_api/detection.vue')
      },
      // ____________________________________________________________
      {
        path: 'record',
        name: 'record',
        component: () => import('@/views/numberCenter/record.vue')
      },
      {
        path: 'chargerecord',
        name: 'chargerecord',
        component: () => import('@/views/numberCenter/chargerecord.vue')
      },
      {
        path: 'apiuserecord',
        name: 'apiuserecord',
        component: () => import('@/views/numberCenter/apiuserecord.vue')
      },
      {
        path: 'apicode',
        name: 'apicode',
        component: () => import('@/views/numberCenter/apicode.vue')
      },
      {
        path: 'webHome',
        name: 'webHome',
        component: () => import('@/views/webHome/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
