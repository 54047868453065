import commonrequest from '../libs/request.js'

export default {
  // 获取图片验证码
  GetCaptcha () {
    return commonrequest.request({
      method: 'get',
      url: '/Common/GetCaptchaGraphic'
    })
  },
  // 获取短信验证码
  GetSmsCode (params) {
    return commonrequest.request({
      method: 'get',
      url: '/Common/GetSmsCode',
      data: params
    })
  },
  // 获取控制台顶部菜单
  GetControlHeadMenu () {
    return commonrequest.request({
      method: 'get',
      url: '/Common/GetControlHeadMenuList'
    })
  },

  // 获取文章列表
  GetNewsList (param) {
    return commonrequest.request({
      method: 'post',
      url: '/Common/GetNewsList',
      data: param
    })
  },

  // 获取文章详情
  GetNewsDetail (nid) {
    return commonrequest.request({
      method: 'get',
      url: '/Common/GetNewsDetail?newsid=' + nid
    })
  }
}
