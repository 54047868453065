const config = {

  // 接口地址
  // baseUrl: 'http://api.jingjiankj.cn',
  baseUrl: 'https://api.shoujikonghao.com',
  // baseUrl: 'http://127.0.0.1:5001',
  // baseUrl: "http://139.129.37.71:8089",
  // 图片地址
  imgUrl: 'http://127.0.0.1:5001',
  // 图片上传地址
  imgUploadUrl: 'http://127.0.0.1:5001',
  // 验证码地址
  vCodeUrl: '/verify/generate-image',
  // 默认头像
  defaultAvatar: '/static/img/default_user.png',

  // 版本
  version: {
    no: '1.0.0',
    desc: '--'
  }
}

export default config
