import config from '@/config/index'
// 这个一个全局的方法 后面可以写在这里
// 调用方法  this.$common.DownLoad(url)
// HTML 调用方法 $common.DownLoad(url)
export default {
  DownLoad (url) {
    // 直接使用浏览器下载
    window.location.href = `${config.baseUrl}/${url}`
  },
  // 获取文件名
  getFileName (url) {
    let name = ''
    if (url !== null && url !== '') {
      name = url.substring(url.lastIndexOf('/') + 1)
    } else {
      name = '结果'
    }
    return name
  }
  // 你可以在这里添加更多的通用方法
}
