import axios from 'axios'
// import router from '@/router'
import baseconfig from '../config/index.js'
import {
//   MessageBox,
  Message, Loading
} from 'element-ui'
import {
  getToken
//   setToken
} from './utils.js'

const instance = axios.create({
  baseURL: baseconfig.baseUrl,
  timeout: 15000
})

let loadingInstance = null
// 请求拦截器
instance.interceptors.request.use(

  config => {
    // 在发送请求之前操作

    const tokenObj = getToken()
    if (tokenObj) {
      // 添加请求头
      config.headers['X-Token'] = tokenObj.ApiToken
      config.headers['X-Uid'] = tokenObj.UserId
    }
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    config.headers.AppType = 8
    config.headers.appVersion = baseconfig.version.no

    // 对get请求进行处理
    if (config.method === 'get' && config.data) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.data)) {
        const value = config.data[propName]
        const part = encodeURIComponent(propName) + '='
        if (value !== null && typeof (value) !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                const params = propName + '[' + key + ']'
                const subPart = encodeURIComponent(params) + '='
                url += subPart + encodeURIComponent(value[key]) + '&'
              }
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (!config.url.includes('QueryOnLinePayStatus')) {
      loadingInstance = Loading.service({ fullscreen: true })
    }

    return config
  },
  error => {
    // 对请求错误操作
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.data) {
      if (response.data.Tag === 10001) {
        Message({
          message: '登录信息已过期，请重新登录' || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        window.localStorage.removeItem('_jjkj_web_token')
        window.open('/', '_self')
        setTimeout(() => {
          // this.$router.push({ path: '/' })
          // uni.redirectTo({
          //   url: '/pages/control/login'
          // })
        }, 1500)
      }
    }
    // 对响应数据操作
    // return response;
    // const loading = Loading.service();
    // loading.close();
    if (!response.config.url.includes('QueryOnLinePayStatus')) {
      loadingInstance.close()
    }

    return Promise.resolve(response)
  },
  async error => {
    Message({
      message: '请求异常，请稍后重试！' || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    // const loading = Loading.service();
    // loading.close();
    return Promise.reject(error)
  }
)

export default instance
