import commonrequest from '../libs/request.js'
// import utils from '../libs/utils.js'
import resquest3 from '../libs/request3.js'

export default {
  // 登录
  AppLogin (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/Login',
      data: params
    })
  },

  LoginBySms (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/LoginBySmsCode',
      data: params
    })
  },

  //  修改密码
  Newpassword (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/FindPassWord',
      data: params
    })
  },

  // 注册
  AppRegister (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/RegisterByMobile',
      data: params
    })
  },

  // 激活产品
  ActiveProduct (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/ActiveProduct',
      data: params
    })
  },

  // 获取用户产品和钱包
  GetUserProductAndWallet () {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/GetUserProductAndWallet'
    })
  },

  // 退出登录
  LogOff () {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/LoginOff'
    })
  },

  // 验证登录
  CheckLogin () {
    return resquest3.request({
      method: 'get',
      url: '/AppUser/CheckLogin'
    })
  },

  // 提交认证资料
  SubmitAuthInfo (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/SubmitAuthInfo',
      data: params
    })
  },

  // 获取认证资料
  GetAuthInfoAndState () {
    return commonrequest.request({
      method: 'get',
      url: '/AppUser/GetAuthInfoAndState'
    })
  },

  // 获取登录日志
  GetUserLoginLogs (params) {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/GetUserLoginLog',
      data: params
    })
  },

  GetHomeLeftMenuList () {
    return commonrequest.request({
      method: 'post',
      url: '/AppUser/GetHomeLeftMenuList'
    })
  },

  ProductFixed (id) {
    return commonrequest.request({
      method: 'get',
      url: '/AppUser/ProductFixed?productid=' + id
    })
  }
}
